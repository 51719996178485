<script setup>
import { useHead } from 'unhead'
import dealsMeta from '@/constants/meta/deals'
import DealsBlock from '@/components/blocks/DealsBlock'
import DealsList from '@/components/blocks/DealsList'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useFetchData from '@/composables/useFetchData'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { dealsAPI } from '@/api/deals-api'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

useHead(dealsMeta)

const store = useStore()

const deals = computed(() => store.getters.deals)
const dealsCurrentCount = computed(() => store.getters.dealsCurrentCount)
const dealsTotalCount = computed(() => store.getters.dealsTotalCount)
const dealsLimit = computed(() => store.getters.dealsLimit)
const isEmptyDealsList = computed(() => store.getters.isEmptyDealsList)
const dealsLoading = computed(() => store.getters.dealsLoading)
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)

const basicRequest = (params) => {
    store.dispatch('getDeals', { ...params, limit: dealsLimit.value })
}
const loading = ref(false)

const parseDeals = async (showNotification = true) => {
    loading.value = true
    dealsAPI
        .parseDeals()
        .then((res) => {
            console.log('parseDeals form res: ', res)
            if (res) {
                if (showNotification) {
                    pushNotification({
                        message: 'Deals were <span>successfully</span> parsed',
                        type: notificationTypes.SUCCESS
                    })
                }
                store.commit('RESET_DEALS')
                store.commit('SET_IS_EMPTY_DEALS_LIST', false)
                store.commit('SET_DEALS_AVERAGE_AMOUNT', 0)
                store.commit('SET_DEALS_TOTAL_AMOUNT', 0)
                initData()
            } else {
                throw new Error()
            }
        })
        .catch((err) => {
            console.log('parseDeals form err: ', err)
            pushNotification({
                message: 'Failed to parse Deals. Try again',
                type: notificationTypes.ERROR
            })
        })
        .finally(() => {
            loading.value = false
        })
}

const [initData, fetchDataOnIntersection] = useFetchData(
    dealsTotalCount,
    dealsCurrentCount,
    substitutePartnerID,
    basicRequest
)

initData()
</script>

<script>
export default {
    name: 'DealsPage'
}
</script>
<template>
    <div class="deals-page">
        <div class="deals-page__header">
            <h1 class="subtitle">Deal List</h1>
            <BaseButton @click="parseDeals" :loading="loading">
                Update
            </BaseButton>
        </div>
        <template v-if="checkHasPermissions([permissionsEnum.READ_DEAD])">
            <DealsBlock class="deals-page__statistics" />
            <DealsList
                :list="deals"
                :is-empty-list="isEmptyDealsList"
                intersection
                @intersection="fetchDataOnIntersection"
                :loading="dealsLoading"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.deals-page {
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        gap: 20px;
    }
    &__statistics {
        max-width: 900px;
        margin-bottom: 56px;
    }
}
</style>
